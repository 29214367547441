import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import GlobalTiles from "../../components/globalTiles"
import LocationMap from "../../components/locationMap"
import ImageTween from "../../components/imageTween"

const Location = () => {
  const lang = "pl"
  // const [tab, setTab] = useState("1")

  return (
    <Layout lang={lang} translation="/location/">
      <SEO title="Lokalizacja" />

      <section className="home-top">
        <div className="global__padd">
          <div className="home-top__wrapper">
            <h2>
              UNITY <br /> <span>Centre</span>
            </h2>
            <h2>Krakowski Kompleks biznesowy</h2>
          </div>
          <ImageTween
            src={require("../../assets/images/location-top-image.jpg")}
            srcSet={require("../../assets/images/location-top-image@2x.jpg")}
            alt=""
          />
        </div>
      </section>

      <section className="location-top">
        <div className="global__padd--bigger">
          <h2>
            UNITY CENTRE to nowoczesny kompleks o powierzchni 50 000 m² w samym
            sercu Krakowa. W jego skład wchodzą 26-kondygnacyjny wieżowiec
            biurowy klasy „AA”, dwa niższe biurowce, czterogwiazdkowy hotel oraz
            luksusowy apartamentowiec.
          </h2>
        </div>
      </section>

      <section className="location-neighborhood">
        <div className="location-neighborhood__wrapper global__padd--bigger">
          <div className="row align-items-center">
            <div className="col-md-6">
              <h2>W dobrym sąsiedztwie</h2>
              <p>
                Kompleks UNITY CENTRE wyróżnia się znakomitą lokalizacją
                bezpośrednio przy Rondzie Mogilskim. Znajduje się w bliskiej
                odległości od Dworca Głównego i galerii handlowej. Dodatkowo, po
                sąsiedzku zlokalizowane są Uniwersytet Ekonomiczny oraz Ogród
                Botaniczny.
              </p>
            </div>
            <div className="col-md-5 offset-md-1">
              <img
                className="img-fluid"
                src={require("../../assets/images/location-map-europe-pl.jpg")}
                srcSet={require("../../assets/images/location-map-europe-pl@2x.jpg")}
                alt=""
              />
            </div>
          </div>
        </div>

        <div className="location-neighborhood__map">
          <LocationMap lang={lang} />
        </div>

        <div className="location-neighborhood__info  global__padd">
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="location-neighborhood__info-ico">
                <img
                  src={require("../../assets/images/location-info-ico-1.svg")}
                  alt=""
                />
                <div>
                  <strong>2,8km / 30min</strong>
                  <span>Do Wzgórza Wawelskiego</span>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="location-neighborhood__info-ico">
                <img
                  src={require("../../assets/images/location-info-ico-2.svg")}
                  alt=""
                />
                <div>
                  <strong>2km / 20min</strong>
                  <span>Do rynku głównego, Sukiennic</span>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="location-neighborhood__info-ico">
                <img
                  src={require("../../assets/images/location-info-ico-3.svg")}
                  alt=""
                />
                <div>
                  <strong>700m / 5min</strong>
                  <span>Do dworca głównego PKP</span>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="location-neighborhood__info-ico">
                <img
                  src={require("../../assets/images/location-info-ico-4.svg")}
                  alt=""
                />
                <div>
                  <strong>19min</strong>
                  <span>
                    Szynobusem z dworca głównego PKP do lotnisko w Balicach
                  </span>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="location-neighborhood__info-ico">
                <img
                  src={require("../../assets/images/location-info-ico-5.svg")}
                  alt=""
                />
                <div>
                  <strong>50</strong>
                  <span>Lini trasportu zbiorowego</span>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="location-neighborhood__info-ico">
                <img
                  src={require("../../assets/images/location-info-ico-6.svg")}
                  alt=""
                />
                <div>
                  <strong>700m / 5min</strong>
                  <span>do centrum handlowego „Galeria Krakowska”</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="location-content global__padd">
        <div className="row no-gutters flex-column-reverse flex-md-row">
          <div className="col-md-6">
            <ImageTween
              src={require("../../assets/images/location-content-image-1-map.jpg")}
              srcSet={require("../../assets/images/location-content-image-1-map@2x.jpg")}
              alt=""
            />
          </div>
          <div className="col-md-6">
            <div className="location-content__inner">
              <h2>Centrum mobilności</h2>
              <p>
                Nasz kompleks zapewnia łatwy dojazd z każdego punktu miasta.
                Dojazd na lotnisko Balice wynosi samochodem 30 minut, a kolejką
                miejską - 17 minut.
              </p>
            </div>
          </div>
        </div>

        <div className="row no-gutters">
          <div className="col-md-7">
            <div className="location-content__inner">
              <p>
                Dobry adres w centrum miasta to także dostęp do sieci
                rozbudowanego transportu miejskiego.
              </p>
              <p>
                Dojedziesz do nas rowerem, skuterem, autobusem, pociągiem i
                samochodem. Dysponujemy 400 miejscami parkingowymi oraz
                możliwością ładowania samochodów elektrycznych, a także 200
                stojakami na rowery.
              </p>
            </div>
          </div>
          <div className="col-md-5">
            <ImageTween
              src={require("../../assets/images/location-content-image-2.jpg")}
              srcSet={require("../../assets/images/location-content-image-2@2x.jpg")}
              alt=""
            />
          </div>
        </div>
      </section>

      {
        // <section className="location-infographic">
        //   <div className="global__padd--bigger">
        //     <p>
        //       Our further infrastructure improvements will intimately link the
        //       complex to the surrounding neighbourhoods via modernized access road
        //       and a clearer traffic grid.
        //     </p>
        //   </div>
        //
        //   <div className="location-infographic__wrapper"></div>
        //   <div className="location-infographic__nav">
        //     <button
        //       className={tab === "1" ? "current" : ""}
        //       onClick={e => setTab("1")}
        //     >
        //       <span>Foot</span>
        //       <img src={require("../../assets/images/ico-foot.svg")} alt="" />
        //     </button>
        //     <button
        //       className={tab === "2" ? "current" : ""}
        //       onClick={e => setTab("2")}
        //     >
        //       <span>Bike</span>
        //       <img src={require("../../assets/images/ico-bike.svg")} alt="" />
        //     </button>
        //     <button
        //       className={tab === "3" ? "current" : ""}
        //       onClick={e => setTab("3")}
        //     >
        //       <span>Scooter</span>
        //       <img src={require("../../assets/images/ico-scooter.svg")} alt="" />
        //     </button>
        //     <button
        //       className={tab === "4" ? "current" : ""}
        //       onClick={e => setTab("4")}
        //     >
        //       <span>Train</span>
        //       <img src={require("../../assets/images/ico-train.svg")} alt="" />
        //     </button>
        //     <button
        //       className={tab === "5" ? "current" : ""}
        //       onClick={e => setTab("5")}
        //     >
        //       <span>Bus</span>
        //       <img src={require("../../assets/images/ico-bus.svg")} alt="" />
        //     </button>
        //     <button
        //       className={tab === "6" ? "current" : ""}
        //       onClick={e => setTab("6")}
        //     >
        //       <span>Car</span>
        //       <img src={require("../../assets/images/ico-car.svg")} alt="" />
        //     </button>
        //   </div>
        // </section>
      }

      <GlobalTiles lang={lang} />
    </Layout>
  )
}

export default Location
